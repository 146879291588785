* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Pretendard;
}
.App {
  width: 100vw;
  height: 100vh;
  display: flex;
}
.Loading img {
  display: block;
  width: 100vw;
  height: 100vh;
}
.App .ant-flex {
  width: 100%;
  background-color: #f8faff;
}
.App .ant-flex .formLogin {
  width: 500px;
  height: 552px;
  border-radius: 10px;
  box-shadow: 1px 0px 8px #e5eaf5;
}
.App .ant-flex .formLogin {
  padding: 50px;
  background-color: white;
}
.App .ant-flex .formLogin .Title h2 {
  width: 100%;
  color: #222;
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}
.App .ant-flex .formLogin .Title h2 span {
  color: #2667ff;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}
.App .ant-flex .formLogin .Title h2 span:nth-child(2) {
  color: #222;
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-left: 5px;
  display: inline-block;
  width: 290px;
}

.App .ant-flex .formLogin .formContent {
  width: 100%;
  margin-top: 50px;
  height: 100%;
}
.App .ant-flex .formLogin .formContent input#id {
  padding: 14px 10px;
  color: #777;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: block;
  border: none;
  border-bottom: 1px solid #ddd;
  width: 100%;
  margin-bottom: 50px;
}
.App .ant-flex .formLogin .formContent input#id:focus {
  outline: none;
}

.App .ant-flex .formLogin .formContent label.label_ID {
  color: #222;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.App .ant-flex .formLogin .formContent input#password {
  padding: 14px 10px;
  color: #777;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: block;
  border: none;
  border-bottom: 1px solid #ddd;
  width: 100%;
  margin-bottom: 50px;
}
.App .ant-flex .formLogin .formContent input#password:focus {
  outline: none;
}
.App .ant-flex .formLogin .formContent label.label_Password {
  color: #222;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.App .ant-flex .formLogin .formContent .btn_Login {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 16px 15px;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  border-radius: 8px;
  background: #2667ff;
  border: none;
}
.App .ant-flex .formLogin .formContent .btn_Login:hover {
  cursor: pointer;
}

.container {
  height: 100vh;
  background-color: #f8faff;
  display: flex;
  flex-direction: column;
}
.container .header {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  justify-content: space-between;
}
.container .header h1 {
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.44px;
}
.container .header .idUser {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 21px;
}
.container .header .idUser div {
  color: #333;
  text-align: right;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
}
.container .header .idUser img {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}
.container .container_Bill {
  padding: 20px 30px;
  flex-grow: 1;
  gap: 12px;
  overflow: hidden;
  overflow-x: auto;
  box-sizing: border-box;
}

.container .container_Bill .scroll_container {
  display: flex;
  gap: 20px;
  height: 100%;
  min-width: max-content;
}

.container .container_Bill .Bill_Detail {
  background-color: white;
  border-radius: 10px;
  /* display: flex;
  flex-direction: column; */
  display: grid;
  /* grid-template-columns: 1fr; */
  /* grid-template-rows: max-content auto max-content; */
  overflow-y: auto;
}
.container .container_Bill .Bill_Detail .time {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(203, 214, 232, 0.5);
  width: 350px;
  flex-shrink: 0;
  padding-top: 26px;
  padding-bottom: 24px;
}
.container .container_Bill .Bill_Detail .time .idBill {
  padding-left: 24px;
  display: inline-flex;
  align-items: center;
  gap: 2px;
}
.container .container_Bill .Bill_Detail .time .idBill img {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-right: 7px;
}
.container .container_Bill .Bill_Detail .time .idBill p {
  color: #111;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 75px;
  height: 34px;
  display: flex;
  align-items: center;
}
.container .container_Bill .Bill_Detail .time .detailTime {
  margin-right: 22px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}
.container .container_Bill .Bill_Detail .time .detailTime .hours {
  display: flex;
  height: 18px;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
}
.container .container_Bill .Bill_Detail .time .detailTime .hours p {
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #2667ff;
}
.container .container_Bill .Bill_Detail .time .detailTime .hours img {
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
}
.container .container_Bill .Bill_Detail .time .detailTime .date {
  width: 127px;
  height: 14px;
  color: #777;
  text-align: right;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
}
.container .container_Bill .Bill_Detail .Detail_Drink {
  padding: 0px 20px;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.container .container_Bill .Bill_Detail .Detail_Drink .ContainerDrink {
  border-bottom: 1px dotted #ccc;
  padding: 20px 0px;
}
.container .container_Bill .Bill_Detail .Detail_Drink .Container_Detail {
  overflow-y: auto;
}
.container
  .container_Bill
  .Bill_Detail
  .Detail_Drink
  .Container_Detail::-webkit-scrollbar {
  background-color: transparent;
  width: 0px;
}
.container .container_Bill .Bill_Detail .Detail_Drink .NameDrink {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  justify-content: space-between;
}
.container .container_Bill .Bill_Detail .Detail_Drink .NameDrink.OptionDrink {
  margin-left: 10px;
  margin-top: 12px;
}

.container
  .container_Bill
  .Bill_Detail
  .Detail_Drink
  .NameDrink.OptionDrink
  p
  img {
  margin-right: 6px;
}
.container .container_Bill .Bill_Detail .Detail_Drink .NameDrink.OptionDrink p {
  display: flex;
  align-items: flex-start;
  font-weight: 400;
}
.container .container_Bill .Bill_Detail .Detail_Drink .NameDrink p {
  overflow: hidden;
  color: #222;
  text-overflow: ellipsis;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}
.container .container_Bill .Bill_Detail .Detail_Drink .NameDrink div {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.container .container_Bill .Bill_Detail .Detail_Drink .Total {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  /* margin-top: auto; */
}
.container .container_Bill .Bill_Detail .Detail_Drink .Total p {
  color: #111;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.28px;
}
.container .container_Bill .Bill_Detail .AddCart {
  background-color: #2667ff;
  padding: 22px 0px;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  cursor: pointer;
}
.container .container_Bill .Bill_Detail .AddCart img {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: -1px;
}
.container .container_Bill .Bill_Detail .AddCart div {
  color: #fff;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
}
.container
  .container_Bill
  .Bill_Detail
  .Detail_Drink
  .NameDrink
  div
  p:last-child {
  color: #222;
  text-align: right;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
}
.footerReception {
  display: flex;
  padding: 35px 30px;
  align-items: flex-start;
  gap: 20px;
  background: #7689a9;
  border-top: 1px solid #e7ecf3;
  color: #fff;
}
.footerReception p {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
}
.footerReception span {
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.6px;
}

.hidden-bill-print {
  position: fixed;
  top: -999999px;
}
