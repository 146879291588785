.wrapBtn {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  -ms-border-radius: 0 0 10px 10px;
  -o-border-radius: 0 0 10px 10px;
}
.btn {
  padding: 0 25px;
  border: none;
  box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
  background-color: #2667ff;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  min-width: 136px;
  outline: none;
  text-align: center;
  font-weight: 700;
 font-size: 16px;
}

.btn:disabled {
  background-color: #cccccc;
  cursor: default;
  color: #666666;
}
